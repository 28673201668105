<script setup lang="ts">
import { trackEvent } from '@@/bits/analytics'
import { browserCan } from '@@/bits/browser'
import currentUser from '@@/bits/current_user'
import device from '@@/bits/device'
import { isAppUsing } from '@@/bits/flip'
import type { ProfilePage as ProfilePageType, ProfileTarget } from '@@/bits/global'
import window from '@@/bits/global'
import { __ } from '@@/bits/intl'
import { navigateTo, navigateToWall } from '@@/bits/location'
import { openNativeShare } from '@@/bits/native_share'
import { isRegistered } from '@@/bits/user_model'
import { SnackbarNotificationType } from '@@/enums'
import ImageThumbnail from '@@/library/v4/components/ImageThumbnail.vue'
import OzAvatar from '@@/library/v4/components/OzAvatar.vue'
import OzContainedButton, {
  OzContainedButtonColorScheme,
  OzContainedButtonSizePreset,
} from '@@/library/v4/components/OzContainedButton.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import OzIconButton, {
  OzIconButtonColorScheme,
  OzIconButtonSizePreset,
} from '@@/library/v4/components/OzIconButton.vue'
import OzIndeterminateSpinner from '@@/library/v4/components/OzIndeterminateSpinner.vue'
import OzPadletLogo from '@@/library/v4/components/OzPadletLogo.vue'
import OzWallCard from '@@/library/v4/components/OzWallCard.vue'
import OzWallListRow, { OzListRowSeparator } from '@@/library/v4/components/OzWallListRow.vue'
import ProfilePageWallCardSkeleton from '@@/library/v4/components/ProfilePageWallCardSkeleton.vue'
import { bridgeUrlClick } from '@@/native_bridge/bridge_url'
import { AccessibilitySetting, useAccessibilitySettingsStore } from '@@/pinia/accessibility_settings_store'
import { useAppBarAccountStore } from '@@/pinia/app_bar_account'
import { useDarkModeStore } from '@@/pinia/dark_mode'
import { useDashAccountsStore } from '@@/pinia/dash_accounts_store'
import { useDashCollectionsStore } from '@@/pinia/dash_collections_store'
import { useWindowSizeStore } from '@@/pinia/window_size'
import type { LibraryId, UserId, Wall } from '@@/types'
import AppBarAccountAvatar from '@@/vuecomponents/AppBarAccountAvatar.vue'
import FollowButton from '@@/vuecomponents/FollowButton.vue'
import GlobalSnackbar from '@@/vuecomponents/GlobalSnackbar.vue'
import LandsideFooter from '@@/vuecomponents/LandsideFooter.vue'
import SkipToContentLink from '@@/vuecomponents/SkipToContentLink.vue'
import { useCopyToClipboard } from '@@/vuecomposables/copy_to_clipboard'
import { useHighContrastMode } from '@@/vuecomposables/useHighContrastMode'
import { computed, defineAsyncComponent, onMounted } from 'vue'

const UserMenu = defineAsyncComponent(() => import('@@/vuecomponents/UserMenu.vue'))
const AccessibilitySettingsModal = defineAsyncComponent(() => import('@@/vuecomponents/AccessibilitySettingsModal.vue'))

enum CardWidth {
  Desktop = 256,
  TabletLandscape = 232,
}

const NO_PADLET_TEXT_ORIGINAL_ILLUSTRATION_WIDTH = 10386
const NO_PADLET_TEXT_ORIGINAL_ILLUSTRATION_HEIGHT = 992
const NO_PADLET_TEXT_ILLUSTRATION_HEIGHT = 120
const NO_PADLET_NUM_PLACEHOLDERS = 8
const NO_PADLET_ORIGINAL_IMAGE_WIDTH = 1112
const NO_PADLET_ORIGINAL_IMAGE_HEIGHT = 688
const NO_PADLET_IMAGE_WIDTH = 278

const profileTarget = window.$profileTarget as ProfileTarget
const profilePage = window.$profilePage as ProfilePageType
const displayName = profileTarget.displayName || profileTarget.name
const isPayingProfile = profilePage.isPayingProfile
const isUserProfile = profileTarget.type === 'user'
const isUserRegistered = isRegistered(currentUser)
const isOwnProfile = currentUser.id === profileTarget.id
// Team profiles can be edited by admin/owner roles even though it's not their own profile
const canIEditProfile = profilePage.canIEditProfile

const { copyToClipboard } = useCopyToClipboard()

const darkModeStore = useDarkModeStore()
const isDarkmode = computed(() => darkModeStore.isDarkMode)
const windowSizeStore = useWindowSizeStore()
const isSmallerThanTabletPortrait = computed(() => windowSizeStore.isSmallerThanTabletPortrait)
const isSmallerThanTabletLandscape = computed(() => windowSizeStore.isSmallerThanTabletLandscape)
const isSmallerThanDesktop = computed(() => windowSizeStore.isSmallerThanDesktop)

const wallCollectionsStore = useDashCollectionsStore()
const activeCollectionWalls = computed(() => wallCollectionsStore.activeCollectionWalls)
const isActiveCollectionFetching = computed(() => wallCollectionsStore.isActiveCollectionFetching)

const dashAccountsStore = useDashAccountsStore()

const appBarAccountStore = useAppBarAccountStore()
const accessibilitySettingsStore = useAccessibilitySettingsStore()

const isApp = device.app || device.electronApp
const isAndroid = device.android

const canBrowserShare = browserCan('share') && (device.mobile || device.tablet)
const copyLinkIconName = computed(() => {
  if (!canBrowserShare) {
    return 'link'
  }
  return isAndroid ? 'share_android_outline' : 'share_outline'
})

const cardWidth = computed(() => {
  if (isSmallerThanDesktop.value) {
    return CardWidth.TabletLandscape
  }
  return CardWidth.Desktop
})

const noWallsMessage = computed(() => {
  return __('%{name} has nothing public to show.', {
    name: displayName,
  })
})
const isEmpty = computed(() => activeCollectionWalls.value?.length === 0)
const numberOfPlaceholderCardsForSingleRow = computed(() => {
  const MAX_CARDS_FOR_SINGLE_ROW_DESKTOP = 4
  const MAX_CARDS_FOR_SINGLE_ROW_TABLET_PORTRAIT = 3

  const maxPlaceholderCards = isSmallerThanTabletLandscape.value
    ? MAX_CARDS_FOR_SINGLE_ROW_TABLET_PORTRAIT
    : MAX_CARDS_FOR_SINGLE_ROW_DESKTOP

  const activeWallsCount = activeCollectionWalls.value?.length ?? 0
  return Math.max(0, maxPlaceholderCards - activeWallsCount)
})

const noPadletTextIllustrationUrl = computed(() => {
  return `https://padlet.net/how-it-works/v1/illustration_call_to_action_${isDarkmode.value ? 'dark' : 'light'}.webp`
})

const noPadletImageUrl = computed(() => {
  return `https://padlet.net/profile-pages/v1/no_padlets_image_${isDarkmode.value ? 'dark' : 'light'}.webp`
})

const noPadletImageHeight = computed(() => {
  return NO_PADLET_IMAGE_WIDTH / (NO_PADLET_ORIGINAL_IMAGE_WIDTH / NO_PADLET_ORIGINAL_IMAGE_HEIGHT)
})

function copyLink() {
  const link = window.location.href
  if (canBrowserShare) {
    openNativeShare({ url: link })
  } else {
    copyToClipboard({
      text: link,
      globalSnackbarOptions: {
        message: __('Profile link copied to clipboard'),
        notificationType: SnackbarNotificationType.success,
        timeout: 1500,
      },
    })
  }
}

function ozWallListRowSeparator(index: number) {
  const isLastRowInList = index === activeCollectionWalls.value?.length - 1
  return isLastRowInList ? OzListRowSeparator.None : OzListRowSeparator.Short
}

function showWall(wall: Wall) {
  navigateTo(wall.links?.show || wall.address)
}

function editProfile(event: Event) {
  bridgeUrlClick(event, profilePage.editProfileUrl, 'internal')
}

function handleUnregisteredFollowClick(): void {
  trackEvent('Profile', 'Prompted to sign up on follow')
}

onMounted(() => {
  useDashCollectionsStore().setAccountCollectionCursor({
    collectionKey: {
      typeKey: 'filter',
      indexKey: isUserProfile ? 'profile' : 'all',
    },
    accountKey: {
      type: isUserProfile ? 'user' : 'library',
      id: profileTarget?.id as UserId | LibraryId,
    },
  })
  useDashCollectionsStore().fetchActiveCollectionWallsNow()
})

if (isAppUsing('highContrastMode')) {
  const user = computed(() => currentUser)
  useHighContrastMode(user)
}
</script>
<template>
  <div class="h-full">
    <header
      class="bg-light-ui-200 dark:bg-dark-ui-200 h-32 tablet-portrait:h-33 tablet-landscape:h-36 desktop-big:h-40 w-full absolute top-0 end-0"
    >
      <SkipToContentLink />
      <!-- Wave -->
      <svg
        class="absolute bottom-0 w-full h-auto text-light-text-100 dark:text-dark-text-100"
        width="1440"
        height="42"
        viewBox="0 0 1440 42"
        fill="none"
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <path
          d="M748.521 28.3453C371 -17.5013 108.65 1.88956 0 22.6762V42H1440V0C1045 70.2593 746.193 28.3453 748.521 28.3453Z"
          fill="currentColor"
        />
      </svg>

      <!-- Navbar -->
      <!-- iOS/Android/Electron native app should not render the navbar -->
      <div v-if="!isApp" class="flex justify-between items-center px-6 pt-4">
        <OzPadletLogo :dark-mode="isDarkmode" class="w-12 z-10" />
        <div v-if="isUserRegistered" class="flex items-center gap-3">
          <OzIconButton
            v-if="isAppUsing('pageSpecificA11ySettings')"
            :label="__('Accessibility settings')"
            :icon-name="'accessibility_outline'"
            :size-preset="OzIconButtonSizePreset.H32px"
            :color-scheme="OzIconButtonColorScheme.SecondaryClear"
            data-testid="profilePageAccessibilitySettingsButton"
            @click="accessibilitySettingsStore.openAccessibilitySettingsModal"
          />
          <AppBarAccountAvatar :user="currentUser" class="z-10" />
        </div>
        <div
          v-else
          class="flex space-s-3"
          role="menubar"
          :aria-label="
            isAppUsing('pageSpecificA11ySettings')
              ? __('Accessibility settings, log in or sign up')
              : __('Log in or sign up')
          "
        >
          <OzIconButton
            v-if="isAppUsing('pageSpecificA11ySettings')"
            role="menuitem"
            :label="__('Accessibility settings')"
            :icon-name="'accessibility_outline'"
            :size-preset="OzIconButtonSizePreset.H32px"
            :color-scheme="OzIconButtonColorScheme.SecondaryClear"
            data-testid="profilePageAccessibilitySettingsButton"
            @click="accessibilitySettingsStore.openAccessibilitySettingsModal"
          />
          <OzContainedButton
            role="menuitem"
            data-testid="profilePageLoginButton"
            :href="'/auth/login'"
            :size-preset="OzContainedButtonSizePreset.H32px"
            :color-scheme="OzContainedButtonColorScheme.Secondary"
            :text="__('Log in')"
          />
          <OzContainedButton
            role="menuitem"
            data-testid="profilePageSignUpButton"
            :href="'/auth/signup'"
            :size-preset="OzContainedButtonSizePreset.H32px"
            :color-scheme="OzContainedButtonColorScheme.Secondary"
            :text="__('Sign up')"
          />
        </div>
      </div>
    </header>

    <main id="main-content" class="min-h-full bg-light-ui-100 dark:bg-dark-ui-100 flex flex-col" tabindex="-1">
      <div class="flex flex-col items-center text-center mx-4">
        <!-- Profile picture/info -->
        <div class="relative mt-18 z-10">
          <OzAvatar
            :class="isUserProfile ? 'rounded-full' : 'rounded-[37.5%]'"
            :src="profileTarget.avatar"
            :alt-text-name="displayName"
            :width="100"
            :height="100"
          />
          <OzIcon
            v-if="isPayingProfile"
            :size="20"
            name="star_filled"
            :class="[
              'absolute bottom-0 end-0 text-light-text-100 dark:bg-canary-500 bg-grape-500 p-2 rounded-full ring ring-light-ui-100 dark:ring-dark-ui-100',
            ]"
          />
        </div>
        <h1
          :class="[
            'text-dark-text-100 dark:text-light-text-100',
            'text-heading-2 tablet-portrait:text-heading-1',
            'font-semibold',
            'break-all',
            'mt-4 tablet-portrait:mt-6',
          ]"
        >
          {{ displayName }}
        </h1>
        <span
          :class="[
            'mt-1 tablet-portrait:mt-2',
            'text-dark-text-200 dark:text-light-text-200',
            'text-body-extra-small tablet-portrait:text-body-small',
            'break-all',
          ]"
          >{{ profileTarget.username || profileTarget.slug }}
        </span>
        <span
          v-if="profileTarget.bio"
          class="mt-2 tablet-portrait:mt-4 text-dark-text-100 dark:text-light-text-100 text-body-small tablet-portrait:text-body break-all"
        >
          {{ profileTarget.bio }}
        </span>

        <!-- Followers/actions row -->
        <div class="flex items-center mt-5 tablet-portrait:mt-6 space-s-4">
          <template v-if="isUserProfile">
            <template v-if="profilePage.isCurrentUserRegistered">
              <div
                v-if="isOwnProfile"
                data-testid="profilePageFollowerCount"
                class="text-body-small text-dark-text-200 dark:text-light-text-200"
              >
                {{ profilePage.followerString }}
              </div>
              <FollowButton
                v-else
                data-testid="profilePageFollowButton"
                role="button"
                :status="profilePage.followerStatus"
                :target-user-id="profileTarget.id"
                :target-user-name="displayName"
              />
            </template>
            <template v-else>
              <OzContainedButton
                data-testid="profilePageSignedOutFollowButton"
                :href="'/auth/signup'"
                :title="__('Sign up to follow %{profileName}', { profileName: displayName })"
                :aria-label="__('Sign up to follow %{profileName}', { profileName: displayName })"
                :text="__('Follow')"
                @click="handleUnregisteredFollowClick"
              />
            </template>
          </template>

          <div class="flex space-s-3">
            <OzIconButton
              v-if="canIEditProfile"
              :icon-size="24"
              icon-name="pencil_outline"
              data-testid="profilePageEditButton"
              :label="__('Edit %{profileName} profile', { profileName: displayName })"
              :href="profilePage.editProfileUrl"
              :color-scheme="OzIconButtonColorScheme.Secondary"
              @click="editProfile"
            />
            <OzIconButton
              :icon-size="24"
              :icon-name="copyLinkIconName"
              data-testid="profilePageLinkButton"
              :label="
                canBrowserShare
                  ? __('Share %{profileName} profile', { profileName: displayName })
                  : __('Copy %{profileName} profile link', { profileName: displayName })
              "
              :color-scheme="OzIconButtonColorScheme.Secondary"
              @click="copyLink"
            />
          </div>
        </div>
      </div>

      <!-- Padlets -->
      <div class="mt-9 tablet-portrait:mt-12 flex flex-1 justify-center">
        <OzIndeterminateSpinner v-if="isActiveCollectionFetching" :size="24" />
        <template v-else-if="isEmpty">
          <div v-if="isSmallerThanTabletLandscape" class="flex justify-center items-center">
            <div class="h-30">
              <ImageThumbnail
                :src="noPadletTextIllustrationUrl"
                :original-image-height="NO_PADLET_TEXT_ORIGINAL_ILLUSTRATION_HEIGHT"
                :original-image-width="NO_PADLET_TEXT_ORIGINAL_ILLUSTRATION_WIDTH"
                :width="
                  NO_PADLET_TEXT_ILLUSTRATION_HEIGHT *
                  (NO_PADLET_TEXT_ORIGINAL_ILLUSTRATION_WIDTH / NO_PADLET_TEXT_ORIGINAL_ILLUSTRATION_HEIGHT)
                "
                :height="NO_PADLET_TEXT_ILLUSTRATION_HEIGHT"
                resize-strategy="fit"
                :process-image="true"
                object-position="right"
                aria-hidden="true"
              />
            </div>
            <p
              :class="[
                'items-center text-center shrink-0',
                'py-16 mx-4',
                'text-dark-text-200 dark:text-light-text-200',
                'text-body-small tablet-portrait:text-body',
                'break-words',
                'w-82 tablet-portrait:w-100',
              ]"
            >
              {{ noWallsMessage }}
            </p>
            <div class="h-30">
              <ImageThumbnail
                :src="noPadletTextIllustrationUrl"
                :original-image-height="NO_PADLET_TEXT_ORIGINAL_ILLUSTRATION_HEIGHT"
                :original-image-width="NO_PADLET_TEXT_ORIGINAL_ILLUSTRATION_WIDTH"
                :width="
                  NO_PADLET_TEXT_ILLUSTRATION_HEIGHT *
                  (NO_PADLET_TEXT_ORIGINAL_ILLUSTRATION_WIDTH / NO_PADLET_TEXT_ORIGINAL_ILLUSTRATION_HEIGHT)
                "
                :height="NO_PADLET_TEXT_ILLUSTRATION_HEIGHT"
                resize-strategy="fit"
                :process-image="true"
                object-position="left"
                aria-hidden="true"
              />
            </div>
          </div>
          <div v-else :class="['grid grid-cols-2 tablet-portrait:grid-cols-4 gap-4 mx-6 auto-rows-min']">
            <template v-for="index in NO_PADLET_NUM_PLACEHOLDERS">
              <ProfilePageWallCardSkeleton :key="`placeholder-card-${index}`" :card-width="cardWidth" />
            </template>
            <div
              :class="[
                'rounded-lg max-w-full bg-light-ui-200 dark:bg-dark-ui-200',
                'col-start-1 col-end-3 row-start-1 row-span-1',
                'tablet-portrait:col-start-2 tablet-portrait:col-end-4 tablet-portrait:row-start-1 tablet-portrait:row-span-2',
                'flex flex-col items-center justify-between overflow-hidden',
                'w-120 h-62 desktop:w-[528px] desktop:h-[272px]',
              ]"
            >
              <p
                :class="[
                  'items-center text-center',
                  'px-6 pb-6 pt-10 box-border',
                  'desktop:px-10 desktop:pt-14',
                  'text-dark-text-200 dark:text-light-text-200',
                  'text-body break-words w-full',
                ]"
              >
                {{ noWallsMessage }}
              </p>
              <ImageThumbnail
                :src="noPadletImageUrl"
                :original-image-height="NO_PADLET_ORIGINAL_IMAGE_HEIGHT"
                :original-image-width="NO_PADLET_ORIGINAL_IMAGE_WIDTH"
                :width="NO_PADLET_IMAGE_WIDTH"
                :height="noPadletImageHeight"
                aria-hidden="true"
                :process-image="true"
                class="!overflow-visible"
              />
            </div>
          </div>
        </template>
        <ul v-else-if="isSmallerThanTabletPortrait" class="flex flex-col w-full">
          <li v-for="(wall, index) in activeCollectionWalls" :key="wall.id">
            <OzWallListRow
              :data-testid="`profilePageWallListRow-${index}`"
              class="px-4"
              :wall="wall"
              :separator="ozWallListRowSeparator(index)"
              @select="showWall"
            />
          </li>
        </ul>
        <ul v-else class="grid grid-cols-3 tablet-landscape:grid-cols-4 h-full gap-4 mx-6 pb-12">
          <li v-for="(wall, index) in activeCollectionWalls" :key="wall.id">
            <OzWallCard
              :data-testid="`profilePageWallCard-${index}`"
              :wall="wall"
              :card-width="cardWidth"
              :x-more-button="false"
              :href="wall.links.permanent"
              @aux-click="navigateToWall(wall)"
              @click="navigateToWall(wall)"
            />
          </li>
          <li
            v-for="index of numberOfPlaceholderCardsForSingleRow"
            :key="`placeholder-card-${index}`"
            :aria-hidden="true"
          >
            <ProfilePageWallCardSkeleton :card-width="cardWidth" />
          </li>
        </ul>
      </div>
      <!-- Footer -->
      <LandsideFooter />
      <!-- Globals/Modals -->
      <UserMenu
        v-if="appBarAccountStore.xUserMenu"
        :user="currentUser"
        :disable-avatar-button="true"
        :anchor-left-offset="24"
        :anchor-top-offset="16"
      />
      <AccessibilitySettingsModal
        v-if="isAppUsing('pageSpecificA11ySettings')"
        :visible="accessibilitySettingsStore.xAccessibilitySettingsModal"
        :portal-selector="'body'"
        :user="dashAccountsStore.user"
        :settings-to-show="[AccessibilitySetting.HighContrastMode, AccessibilitySetting.AppMessagesAutoDismiss]"
        @close="accessibilitySettingsStore.closeAccessibilitySettingsModal"
      />
      <GlobalSnackbar />
    </main>
  </div>
</template>

<style lang="postcss">
@import '@@/styles/4/kit';

html,
body {
  height: 100%;
}
</style>
