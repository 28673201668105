<template>
  <OzContainedButton
    :color-scheme="buttonColorScheme"
    :data-status="currentStatus"
    :text="buttonText"
    :title="buttonLabel"
    :aria-label="buttonLabel"
    aria-live="polite"
    @click="handleClick"
  />
</template>

<script>
import { trackEvent } from '@@/bits/analytics'
import { captureException } from '@@/bits/error_tracker'
import { __ } from '@@/bits/intl'
import { UserFollows as UserFollowsApi } from '@@/dashboard/padlet_api'
import OzContainedButton, { OzContainedButtonColorScheme } from '@@/library/v4/components/OzContainedButton.vue'

const FollowStatus = {
  Following: 'following',
  NotFollowing: 'notfollowing',
}

export default {
  components: {
    OzContainedButton,
  },
  props: {
    status: {
      default: FollowStatus.Following,
    },
    targetUserId: Number,
    targetUserName: String,
  },
  data: function () {
    // only mutate currentStatus, don't mutate props coming from parent
    return {
      currentStatus: this.status,
    }
  },
  computed: {
    isFollowing: function () {
      return this.currentStatus === FollowStatus.Following
    },
    buttonColorScheme: function () {
      return this.isFollowing ? OzContainedButtonColorScheme.Active : OzContainedButtonColorScheme.Primary
    },
    buttonText: function () {
      return this.isFollowing ? __('Following') : __('Follow')
    },
    buttonLabel: function () {
      return this.isFollowing
        ? __('Unfollow %{targetUserName}', { targetUserName: this.targetUserName })
        : __('Follow %{targetUserName}', { targetUserName: this.targetUserName })
    },
  },
  methods: {
    async follow() {
      try {
        await UserFollowsApi.create({ jsonData: { user_id: this.targetUserId } })
        this.currentStatus = FollowStatus.Following
      } catch (e) {
        captureException(e)
        alert(__('Oops! Something went wrong. Please try again.'))
      }
    },
    async unfollow() {
      try {
        await UserFollowsApi.delete({ jsonData: { user_id: this.targetUserId } })
        this.currentStatus = FollowStatus.NotFollowing
      } catch (e) {
        captureException(e)
        alert(__('Oops! Something went wrong. Please try again.'))
      }
    },
    handleClick: function () {
      if (this.isFollowing) {
        this.unfollow()
        trackEvent('Profile', 'Unfollowed user')
      } else {
        this.follow()
        trackEvent('Profile', 'Followed user')
      }
    },
  },
}
</script>
