<script setup lang="ts">
import { dir } from '@@/bits/current_dir'
import { __ } from '@@/bits/intl'
import { SubscriptionFilter, buildProductPagePath, buildSubscriptionPagePath } from '@@/bits/landside_helper'
import { buildUrlFromPath } from '@@/bits/location'
import ImageThumbnail from '@@/library/v4/components/ImageThumbnail.vue'
import OzIcon from '@@/library/v4/components/OzIcon.vue'
import OzIconButton, {
  OzIconButtonColorScheme,
  OzIconButtonSizePreset,
} from '@@/library/v4/components/OzIconButton.vue'
import OzPadletLogo, {
  OzPadletLogoColorScheme,
  OzPadletLogoSizePreset,
} from '@@/library/v4/components/OzPadletLogo.vue'
import OzPlainButton, {
  OzPlainButtonColorScheme,
  OzPlainButtonSizePreset,
} from '@@/library/v4/components/OzPlainButton.vue'
import { useDarkModeStore } from '@@/pinia/dark_mode'
import { LandsidePage, ProductPageTab, useLandsideNavigationStore } from '@@/pinia/landside_navigation'
import { useWindowSizeStore } from '@@/pinia/window_size'
import LandsideCallToAction from '@@/vuecomponents/LandsideCallToAction.vue'
import type { LandsideNavigation } from '@@/vuecomponents/LandsideFooterSection.vue'
import LandsideFooterSection from '@@/vuecomponents/LandsideFooterSection.vue'
import LocaleSelector from '@@/vuecomponents/LocaleSelector.vue'
import { storeToRefs } from 'pinia'
import { computed, ref } from 'vue'

const { isSmallerThanDesktop, windowWidth } = storeToRefs(useWindowSizeStore())
const { isDarkMode } = storeToRefs(useDarkModeStore())
const { currentPage, landsidePages, isUserLoggedIn } = storeToRefs(useLandsideNavigationStore())

const socialMediaIcons = [
  { icon: 'youtube_outline', url: 'https://www.youtube.com/@padlet', label: 'Youtube' },
  { icon: 'x_logo', url: 'https://twitter.com/padlet', label: 'Twitter' },
  { icon: 'facebook_outline', url: 'https://www.facebook.com/padlet/', label: 'Facebook' },
  { icon: 'instagram_outline', url: 'https://www.instagram.com/padlet', label: 'Instagram' },
  { icon: 'linkedin_outline', url: 'https://www.linkedin.com/company/padlet', label: 'Linkedin' },
]

const productNavigationItems: LandsideNavigation[] = [
  { name: __('How it works'), url: buildUrlFromPath(buildProductPagePath(ProductPageTab.HOW_IT_WORKS)) },
  { name: __('For education'), url: buildUrlFromPath(buildProductPagePath(ProductPageTab.EDUCATION)) },
  { name: __('Apps and integrations'), url: 'https://www.padlet.com/about/apps' },
  { name: __('Developer docs'), url: 'https://docs.padlet.dev/' },
]

const subscriptionsNavigationItems: LandsideNavigation[] = [
  { name: __('All'), url: buildUrlFromPath(buildSubscriptionPagePath(SubscriptionFilter.ALL)) },
  { name: __('Best for home'), url: buildUrlFromPath(buildSubscriptionPagePath(SubscriptionFilter.HOME)) },
  { name: __('Best for business'), url: buildUrlFromPath(buildSubscriptionPagePath(SubscriptionFilter.BUSINESS)) },
  { name: __('Best for education'), url: buildUrlFromPath(buildSubscriptionPagePath(SubscriptionFilter.EDUCATION)) },
]

const companyNavigationItems: LandsideNavigation[] = [
  { name: __('About us'), url: 'https://www.padlet.com/about/us' },
  { name: __('Jobs'), url: 'https://www.angel.co/company/padlet/jobs' },
  { name: __('Blog'), url: 'https://padlet.blog/' },
]

const supportNavigationItems: LandsideNavigation[] = [
  { name: __('Knowledge base'), url: 'https://www.padlet.help' },
  { name: __('Video tutorials'), url: 'https://www.youtube.com/padlet' },
  { name: __('Examples'), url: 'https://www.padlet.com/gallery' },
  { name: __('Contact us'), url: 'https://www.padlet.com/contact-us' },
]

const legalNavigationItems: LandsideNavigation[] = [
  { name: __('Terms of use'), url: 'https://legal.padlet.com/terms' },
  { name: __('Privacy policy'), url: 'https://legal.padlet.com/privacy' },
  { name: __('Content policy'), url: 'https://legal.padlet.com/content' },
  { name: __('Accessibility statement'), url: 'https://legal.padlet.com/accessibility' },
]

const footerIllustrationDarkUrl = 'https://padlet.net/homepage/v4/footer_illustration_dark.webp'
const footerIllustrationLightUrl = 'https://padlet.net/homepage/v4/footer_illustration_light.webp'
const ILLUSTRATION_ORIGINAL_IMAGE_WIDTH = 11520
const ILLUSTRATION_ORIGINAL_IMAGE_HEIGHT = 1152
const illustrationContainer = ref<HTMLDivElement>()

// We use maxAvailableWidth to handle the case when users zoom in/out which causes
// the windowWidth to change
const maxAvailableWidth = computed((): number => {
  if (!illustrationContainer.value) return windowWidth.value
  return Math.max(illustrationContainer.value.clientWidth, windowWidth.value)
})

const illustrationHeight = computed((): number => {
  if (isSmallerThanDesktop.value) return 80
  return 138
})

const illustrationWidth = computed((): number => {
  // We calculate the width of the illustration using the ratio (width / height) of the original image
  return illustrationHeight.value * (ILLUSTRATION_ORIGINAL_IMAGE_WIDTH / ILLUSTRATION_ORIGINAL_IMAGE_HEIGHT)
})

const numberOfIllustrationsDisplayed = computed((): number => {
  if (maxAvailableWidth.value < illustrationWidth.value) return 1
  return Math.ceil(maxAvailableWidth.value / illustrationWidth.value)
})

const lastIllustrationWidth = computed((): number => {
  const remainingWidth = maxAvailableWidth.value - (numberOfIllustrationsDisplayed.value - 1) * illustrationWidth.value
  return remainingWidth === 0 ? illustrationWidth.value : remainingWidth
})

function handleObjectPosition(shouldReverse: boolean): string {
  if (shouldReverse) {
    // For rtl, we need to position the object from the left
    if (dir() === 'ltr') return 'right'
    return 'left'
  }
  if (dir() === 'ltr') return 'left'
  return 'right'
}

const xLandsideCallToAction = computed((): boolean => {
  if (isUserLoggedIn.value) return false
  if (currentPage.value === LandsidePage.Product) return true
  if (currentPage.value === LandsidePage.Subscriptions) return true

  return false
})
</script>

<template>
  <footer :class="['flex flex-col', 'mt-auto']">
    <LandsideCallToAction v-if="xLandsideCallToAction" />
    <div
      ref="illustrationContainer"
      :class="['flex h-20 desktop:h-[138px] -mb-0.25', xLandsideCallToAction && 'mt-6 desktop:mt-24']"
    >
      <template v-for="index in numberOfIllustrationsDisplayed">
        <ImageThumbnail
          :key="index"
          :style="
            index < numberOfIllustrationsDisplayed
              ? { width: `${illustrationWidth}px` }
              : { width: `${lastIllustrationWidth}px` }
          "
          data-testid="footerIllustrationImage"
          :src="isDarkMode ? footerIllustrationDarkUrl : footerIllustrationLightUrl"
          :original-image-height="ILLUSTRATION_ORIGINAL_IMAGE_HEIGHT"
          :original-image-width="ILLUSTRATION_ORIGINAL_IMAGE_WIDTH"
          :width="illustrationWidth"
          :height="illustrationHeight"
          resize-strategy="fit"
          :process-image="true"
          :object-position="handleObjectPosition(index % 2 === 0)"
          :flip-direction="index % 2 === 0 ? 'horizontal' : undefined"
          :lazy-loading="false"
          aria-hidden="true"
        />
      </template>
    </div>
    <div :class="['flex flex-col', 'desktop:items-center', 'bg-light-ui-200 dark:bg-dark-ui-200']">
      <div
        :class="[
          'flex flex-col-reverse desktop:flex-row',
          'desktop:pt-12 desktop:pb-16',
          'desktop:items-start',
          'desktop:w-320',
        ]"
      >
        <!-- :Padlet segment -->
        <div
          :class="[
            'flex flex-col',
            'items-center desktop:items-start',
            'pt-0.75',
            'mt-8 desktop:mt-0',
            'mb-11 desktop:mb-0',
            'desktop:w-70',
          ]"
        >
          <OzPadletLogo
            :size-preset="OzPadletLogoSizePreset.W86px"
            :color-scheme="OzPadletLogoColorScheme.Text200"
            :href="landsidePages[0].href"
            class="mb-2"
          />
          <span
            :class="[
              'w-[186px]',
              'mb-5',
              'text-body-extra-small',
              'text-center desktop:text-start',
              'text-dark-text-200 dark:text-light-text-200',
            ]"
          >
            {{ __('Oh wow, you’re so beautiful. Just saying!') }}</span
          >
          <ul class="flex space-s-6 desktop:space-s-3 mb-6" :aria-label="__('Social media')">
            <li v-for="(socialMediaIcon, index) in socialMediaIcons" :key="index">
              <OzPlainButton
                :size-preset="OzPlainButtonSizePreset.H24px"
                :color-scheme="OzPlainButtonColorScheme.TertiaryIcon"
                :href="socialMediaIcon.url"
                :aria-label="socialMediaIcon.label"
                :data-testid="socialMediaIcon.label"
                class="group"
              >
                <OzIcon
                  :name="socialMediaIcon.icon"
                  class="group-active:text-grape-500 dark:group-active:text-canary-500"
                />
              </OzPlainButton>
            </li>
          </ul>
          <div class="flex space-s-2.5 items-center">
            <OzIconButton
              icon-name="accessibility_outline"
              :label="__('Accessibility')"
              :size-preset="OzIconButtonSizePreset.H32px"
              :icon-size="20"
              :color-scheme="OzIconButtonColorScheme.Secondary"
              data-testid="accessibilityButton"
              href="https://legal.padlet.com/accessibility"
            />
            <section :aria-label="__('Language selection')">
              <LocaleSelector />
            </section>
          </div>
        </div>

        <div
          :class="[
            'flex flex-1 flex-col desktop:flex-row',
            'desktop:justify-around',
            'desktop:space-s-4',
            'desktop:ms-4',
          ]"
        >
          <!-- Products segment -->
          <LandsideFooterSection :title="__('Product')" :navigation-items="productNavigationItems" class="flex-1" />
          <!-- Subscription segment -->
          <LandsideFooterSection
            :title="__('Subscriptions')"
            :navigation-items="subscriptionsNavigationItems"
            class="flex-1"
          />
          <!-- Company segment -->
          <LandsideFooterSection :title="__('Company')" :navigation-items="companyNavigationItems" class="flex-1" />
          <!-- Help segment -->
          <LandsideFooterSection :title="__('Support')" :navigation-items="supportNavigationItems" class="flex-1" />
          <!-- Legal segment -->
          <LandsideFooterSection :title="__('Legal')" :x-divider="false" :navigation-items="legalNavigationItems" />
        </div>
      </div>
    </div>
  </footer>
</template>
