// @file Javascript for Profile page. Simple run-time Vue app.
import { currentUserPromise } from '@@/bits/current_user'
import { captureException, setScope } from '@@/bits/error_tracker'
import window from '@@/bits/global'
import { setupVueApp } from '@@/bits/vue'
import ProfilePage from '@@/vuecomponents/ProfilePage.vue'
import { configure as configureArvo } from '@padlet/arvo'

const { arvoConfig } = window.ww.vueStartingState
void configureArvo(arvoConfig)

currentUserPromise.then((user) => setScope({ userId: user.id })).catch(captureException)

setupVueApp({
  el: '#app',
  usePinia: true,
  rootComponent: ProfilePage,
}).catch(captureException)
